
@mixin isp-modalContextContainer() {
	@include isp-container();
	padding-left: $base6;
	padding-right: 0;
	padding-top: $headerHeightSmall;

	@include media($smallLayout) {
		padding-top: $headerHeightMedium;
	}
}

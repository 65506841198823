.isp-rankingInfo {
	page-break-inside: avoid;
	position: relative;

	.isp-plot & {
		margin-bottom: $base2;
	}


	&__download {
		display: none;
	}


	&__header {
		display: block;
	}


	&__info {
		@include font(detail);

		.isp-plot & {
			@include clearfix();
		}
	}


	&__infoEntry {
		&.isp-js-hidden {
			display: none;
		}

		.isp-plot & {
			float: left;
			margin-right: $gutter;
			width: staticColumnSize(1, 3, $gutter);

			&:last-child {
				margin-right: 0;
			}
		}
	}


	&__infoName {
		color: $colorDecoration;
		display: inline;
		margin: 0;


		&::after {
			content: ':';
		}
	}


	&__infoValue {
		display: inline;
		margin: 0;
		text-indent: 0;
	}


	&__title {
		@include font(base);
		font-weight: 600;
	}
}

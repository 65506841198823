.isp-contentInfo {
	@include font(detail);


	&__date {
		&::before {
			.isp-contentInfo__category + & {
				content: ' • ';
			}
		}
	}
}

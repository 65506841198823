.isp-configuratorMap {
	position: absolute;
	left: 0;
	top: -$base4;
	width: 100%;
	bottom: -$base2;


	&__map {
		background-color: $colorBgBase;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	svg {
		.isp-js-enabled {
			fill: $colorBgMapEnabled;

			&:not(.isp-js-selected) {
				@include onHover() {
					fill: $colorBgMapHover;
				}
			}
		}

		.isp-js-selected {
			fill: $colorBgMapSelected;
		}
	}
}

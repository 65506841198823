.isp-indication {
	background: $colorBgIndication;
	display: block;
	height: $indicationHeight;
	overflow: hidden;
	position: relative;
	width: staticColumnSize(4, 12, $gutter);

	@include media($largeLayout) {
		visibility: hidden;
		opacity: 0;
	}


	&__indicator {
		background: $colorIndication;
		height: $indicationHeight;
		left: 3px;
		position: absolute;
		top: 0;
		transform: translateX(-100%);
		width: 100%;

		.isp-page--withBg & {
			background: $colorIndicationInverted;
		}
	}
}


.isp-linkReference {
	white-space: nowrap;


	&::before {
		content: '[';
		margin-left: 0.2em;
	}


	&::after {
		content: ']';
	}
}

.isp-plotLegend {
	@include font(legend);
	margin-bottom: $base2;
	page-break-inside: avoid;
	position: relative;


	&__container {
		display: none;
		flex-direction: column;

		&.isp-js-selected {
			@include clearfix();
			display: block;
		}
	}


	&__entry {
		position: relative;
		padding-left: $base2;
	}


	&__entrySymbol {
		display: inline-block;
		margin-right: $baseHalf;
		position: absolute;
		left: 0;
		top: getCalculatedLineHeight(legend) / 2;
		transform: translateY(-50%);
		width: $base;

		@include media($smallLayout) {
			top: getCalculatedLineHeight(legend, $smallLayout) / 2;
		}

		&--pattern {
			background: repeating-linear-gradient(-45deg, $colorBgBase, $colorBgBase 1px, $colorBase 1px, $colorBase 2.1px);
		}

		.isp-plotLegend__entry--scenario & {
			border-radius: 50%;
			height: $base;
		}

		.isp-plotLegend__entry--shade & {
			height: $base;
		}
	}


	&__section {
		float: left;
		width: staticColumnSize(1, 3, $gutter);

		&:not(:first-child) {
			margin-left: $gutter;
		}

		.isp-plotLegend__container--temperature & {
			width: staticColumnSize(1, 2, $gutter);
		}
	}


	&__title {
		@include font(legend);
		font-weight: 600;
		margin-bottom: $base;
	}
}

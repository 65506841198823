.isp-pageContainer {
	.isp-body.isp-js-pageReportConfig & {
		height: 100%;
	}


	&__page {
		&--reportConfig {
			height: 100%;
		}
	}
}

.isp-temporaryTextImage {
	&__title {
		@include font(h3);
		margin-bottom: $base;
	}


	&__item {
		& + & {
			margin-top: $base2;
		}
	}


	&__picture {
		display: none;
	}



	&__text {
		a {
			@include isp-link();
		}
	}
}

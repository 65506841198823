
@import "../../isp/blocks/gallery";

.isp-gallery {
	display: flex;
	flex-wrap: wrap;

	&__item {
		width: 50%;
	}
}

@import "../../isp/blocks/glossary-list";

.isp-glossaryList {
	&__filter {
		&::before {
			content: '';
			display: none;
		}
	}
}

// common functions, mixins
@import "common/functions/index";
@import "common/mixins/index";
@import "common/generic/index";

// config
@import "isp-print/config/config";
@import "isp/config/font-faces";


// media queries mixin
@import "include-media/dist/_include-media";

// project animations, functions, mixins
@import "isp/functions/index";
@import "isp/mixins/index";
@import "isp/animations/index";

@import "leaflet/dist/leaflet.css";

// somehow this could help the page-break-* style rule to work in the browsers
* {
	position: relative;
}

// project blocks
@import "isp/blocks/anchor";
@import "isp/blocks/button";
@import "isp/blocks/call-to-action";
@import "isp/blocks/configurator";
@import "isp/blocks/configurator-control";
@import "isp/blocks/configurator-map";
@import "isp/blocks/configurator-search";
@import "isp/blocks/configurator-section";
@import "isp/blocks/configurator-summary";
@import "isp/blocks/content-info";
@import "isp/blocks/external-video";
@import "isp/blocks/glossary";
@import "isp/blocks/indication";
@import "isp/blocks/map-legend";
@import "isp/blocks/main-menu";
@import "isp/blocks/search";
@import "isp/blocks/temporary-text-link";


// print style blocks
@import "isp-print/blocks/articles-slider.scss";
@import "isp-print/blocks/block";
@import "isp-print/blocks/body";
@import "isp-print/blocks/country-details";
@import "isp-print/blocks/country-ranking-map";
@import "isp-print/blocks/cover";
@import "isp-print/blocks/data-filters";
@import "isp-print/blocks/featured-story";
@import "isp-print/blocks/footer";
@import "isp-print/blocks/home-infographic";
@import "isp-print/blocks/gallery";
@import "isp-print/blocks/glossary-list";
@import "isp-print/blocks/glossary-nav";
@import "isp-print/blocks/header";
@import "isp-print/blocks/html";
@import "isp-print/blocks/image";
@import "isp-print/blocks/link-reference";
@import "isp-print/blocks/links-reference";
@import "isp-print/blocks/main";
@import "isp-print/blocks/map-legend";
@import "isp-print/blocks/modal";
@import "isp-print/blocks/page";
@import "isp-print/blocks/page-container";
@import "isp-print/blocks/page-menu";
@import "isp-print/blocks/plot";
@import "isp-print/blocks/plot-legend";
@import "isp-print/blocks/plot-switch";
@import "isp-print/blocks/plot-tooltip";
@import "isp-print/blocks/ranking-map";
@import "isp-print/blocks/ranking-graph";
@import "isp-print/blocks/ranking-info";
@import "isp-print/blocks/temporary-text-image";
@import "isp-print/blocks/tooltip";
@import "isp-print/blocks/page-menu-overlay";
@import "isp-print/blocks/ugc";

@page {
	margin: 1cm;
}


.isp-html {
	font-size: $htmlFontSize;
	position: relative;
}



@mixin isp-subContainer($pushing: false) {
	display: block;
	width: 100%;

	@include media($smallLayout) {
		margin-left: auto;
		margin-right: 0;
		max-width: staticColumnSize(10, 12, $gutter);
	}

	@include media($largeLayout) {
		margin-left: staticColumnPush(3, 12, $gutter);
		margin-right: auto;
		max-width: staticColumnSize(7, 12, $gutter);

		@if ($pushing) {
			margin-right: 0;
			max-width: staticColumnSize(9, 12, $gutter);
		}
	}
}

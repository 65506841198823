.isp-countryDetails {
	&__caption {
		@include font(detail);
		color: $colorImgCaption;
		margin-top: $base2;
	}


	&__countryName {
		@include media($largeLayout) {
			width: 90%;
			padding-right: $gutter / 2;
		}
	}


	&__figure {
		margin-top: $base;
	}


	&__item {
		padding: $baseHalf 0;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		& + & {
			border-top: 1px solid $colorBorderLight;
		}
	}


	&__items {
		@include font(base);
	}


	&__label {
		display: inline-block;
		margin-right: 0.5em;

		margin-right: 0;
		padding-right: ($gutter / 2);
		width: 45%;

		&::after {
			content: ':';
		}
	}


	&__title {
		@include font(h3);

		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-end;
	}


	&__value {
		display: inline-block;
		font-weight: 600;
		text-indent: 0;
		margin-left: 0;

		width: 45%;
		padding-right: ($gutter / 2);
	}

	&__rank {
		display: block;
		text-indent: 0;
		margin-left: 0;

		width: 10%;
		text-align: left;
	}


	&__rankLabel {
		@include font(base);
		font-weight: normal;

		display: block;
		text-align: left;
		width: 10%;
	}


	&__rankSingleLabel {
		display: none;
	}
}

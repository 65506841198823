.isp-mainMenu {
	color: $colorInverted;


	&__container {
		@include isp-modalContextContainer();
	}


	&__content {
		@include isp-modalContextBg();
		padding: $base3 0;
		position: relative;

		@include media($smallLayout) {
			margin-left: staticColumnPush(4, 12, $gutter);
			padding: $base6 0;
		}

		@include media($largeLayout) {
			margin-left: staticColumnPush(7, 12, $gutter);
		}
	}


	&__item {
		@include font(base);
		margin-bottom: $base;

		&--section {
			margin-top: $base2;

			&:first-child {
				margin-top: 0;
			}
		}
	}


	&__link {
		@include ease(color, $duration2);
		color: $colorInverted;
		text-decoration: none;

		@include onHover() {
			color: $colorHighlight;
		}
	}


	&__nav {
		padding: 0 $base3;

		@include media($smallLayout) {
			padding: 0 $base6;
			padding-left: staticColumnPush(2, 8, $gutter);
		}

		@include media($largeLayout) {
			padding-left: staticColumnPush(1, 5, $gutter);
		}
	}


	&__sectionTitle {
		@include font(h3);
		margin-bottom: $base;
	}
}

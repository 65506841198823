.isp-cover {
	&__bg {
		display: none;
	}


	&__callToAction {
		display: none;
	}


	&__category {
		display: none;
	}


	&__extra {
		display: none;
	}


	&__reportAddIndicators {
		display: none;
		margin-top: $base2;
	}


	&__reportInfo {
		@include clearfix();
		width: 100%;
	}


	&__reportInfoIndicatorLink {
		color: inherit;
		padding-bottom: $baseHalf / 2;
		text-decoration: none;
	}


	&__reportInfoIndicatorIcon {
		display: none;
	}


	&__reportInfoTerm {
		@include font(base);
		margin-left: 0;
		width: 25%;
		float: left;

		.isp-cover__reportInfoValue + & {
			margin-top: $base;
		}

		&--indicators {
			float: right;
			width: 75%;
		}


		&::after {
			content: ':';
		}
	}


	&__reportInfoValue {
		@include font(h2);
		margin-left: 0;
		width: 75%;
		float: right;

		&--indicators {
			@include font(base);
			font-weight: 600;
			margin-top: 0;
		}
	}


	&__reportShowAllAction {
		display: none;
	}



	&__text {
		@include font(important);
		margin-top: $base2;

		.isp-page:not(.isp-page--home) & {
			@include font(base);
			font-weight: 600;
		}
	}


	&__title {
		@include font(h1);
		order: 2;

		.isp-page--home & {
			border-bottom: 1px solid currentColor;
			border-top: 1px solid currentColor;
			padding-top: $base2;
			padding-bottom: $base3;
		}
	}
}

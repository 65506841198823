@import "isp/config/font-sizes";

$htmlFontSize: 62.5%;


$fontSizes: map-merge(
	$fontSizes,
	(
		base: (
			font-family: $fontFamilyMain,
			font-size: pxToRem(16),
			font-weight: normal,
			line-height: (24 / 16),
			variants: (
				$smallLayout: (
					font-size: pxToRem(20),
					line-height: (32 / 20)
				)
			)
		),
		baseVariant: (
			font-family: $fontFamilyMain,
			font-size: pxToRem(14),
			font-weight: normal,
			line-height: (20 / 14),
			variants: (
				$smallLayout: (
					font-size: pxToRem(20),
					line-height: (32 / 20)
				)
			)
		),
		pageMenu: (
			font-family: $fontFamilyMain,
			font-size: pxToRem(14),
			font-weight: normal,
			line-height: (20 / 14),
			variants: (
				$smallLayout: (
					font-size: pxToRem(20),
					line-height: (32 / 20)
				),
				$largeLayout: (
					font-size: pxToRem(16),
					line-height: (24 / 16)
				)
			)
		),
		important: (
			font-family: $fontFamilyMain,
			font-size: pxToRem(18),
			font-weight: normal,
			line-height: (28 / 18),
			variants: (
				$smallLayout: (
					font-size: pxToRem(26),
					line-height: (40 / 26)
				)
			)
		),
		h1: (
			font-family: $fontFamilyAlternate,
			font-size: pxToRem(36),
			font-weight: 600,
			line-height: (48 / 36),
			variants: (
				$smallLayout: (
					font-size: pxToRem(72),
					line-height: (94 / 72)
				)
			)
		),
		h2: (
			font-family: $fontFamilyAlternate,
			font-size: pxToRem(22),
			font-weight: 600,
			line-height: (32 / 22),
			variants: (
				$smallLayout: (
					font-size: pxToRem(36),
					line-height: (52 / 36)
				)
			)
		),
		h3: (
			font-family: $fontFamilyMain,
			font-size: pxToRem(18),
			font-weight: 600,
			line-height: (28 / 18),
			variants: (
				$smallLayout: (
					font-size: pxToRem(26),
					line-height: (40 / 26)
				)
			)
		),
		headerButton: (
			font-size: pxToRem(14),
			font-weight: normal,
			line-height: (20 / 14),
			variants: (
				$smallLayout: (
					font-size: pxToRem(16),
					line-height: (24 / 16)
				)
			)
		),
		action: (
			font-family: $fontFamilyMain,
			font-size: pxToRem(14),
			font-weight: bold,
			letter-spacing: (0.44em / 14),
			line-height: (20 / 14),
			text-transform: uppercase,
			variants: (
				$smallLayout: (
					font-size: pxToRem(16),
					letter-spacing: (0.5em / 16),
					line-height: (24 / 16)
				)
			)
		),
		detail: (
			font-family: $fontFamilyMain,
			font-size: pxToRem(14),
			font-weight: normal,
			line-height: (20 / 14),
			variants: (
				$smallLayout: (
					font-size: pxToRem(16),
					line-height: (24 / 16)
				)
			)
		),
		configuratorControl: (
			font-family: $fontFamilyMain,
			font-size: pxToRem(14),
			font-weight: normal,
			line-height: (20 / 14),
			variants: (
				$smallLayout: (
					font-size: pxToRem(16),
					line-height: (24 / 16)
				)
			)
		),
		detailVariant: (
			font-size: pxToRem(12),
			font-weight: normal,
			line-height: (18 / 12),
			variants: (
				$smallLayout: (
					font-size: pxToRem(14),
					line-height: (20 / 14)
				)
			)
		),
		navDetail: (
			font-size: pxToRem(12),
			font-weight: normal,
			line-height: (17 / 12),
			variants: (
				$smallLayout: (
					font-size: pxToRem(14),
					line-height: (20 / 14)
				)
			)
		)
	)
);

.isp-rankingGraph {
	$canvasSpacing: $base5;
	$comparisonGraphSpacing: $base2;
	$indicatorPointerSpacing: 13px;
	$indicatorWidth: 3px;

	@include storeValuesForJs(('showBackgroundLines': true));
	page-break-inside: avoid;


	&__canvasWrapper {
		height: $canvasSpacing;

		canvas {
			display: block;
		}
	}


	&__comparisonGraph {
		background-color: #EAEFEE;
		display: block;
		margin-top: $base1;
		height: $comparisonGraphSpacing;
		position: relative;
	}

	&__comparisonGraphItem {
		height: 100%;
		position: absolute;
		top: 0;
	}



	&__detail {
		@include font('detailVariant');
		color: $colorRankingDetail;

		&--graphFooter {
			@include font('detail');
		}
	}


	&__graph {
		position: relative;
		width: 100%;
	}


	&__graphList {
		display: flex;
		flex-wrap: wrap;
	}


	&__graphListItem {
		width: 50%;

		&:nth-child(odd) {
			text-align: left;
		}

		&:nth-child(even) {
			text-align: right;
		}
	}


	&__graphFooter {
		display: inline-block;
		margin-top: $base4;
		min-width: staticColumnSize(4, 10, $gutter);
	}


	&__heading {
		@include font('detail');
		font-weight: bold;
	}


	&__indicator {
		background-color: $colorRankingIndicator;
		height: $comparisonGraphSpacing + $canvasSpacing + $base1;
		left: - 1px;
		position: absolute;
		top: 0;
		width: $indicatorWidth;
	}


	&__indicatorDetail {
		@include font('detail');
		position: absolute;
		top: 100%;
		padding-top: $base1 / 2;
		white-space: nowrap;
		left: $indicatorWidth;

		&.isp-js-middle {
			left: 50%;
			transform: translateX(-50%);
		}

		&.isp-js-last {
			padding-right: $indicatorWidth;
			transform: translateX(-100%);
		}
	}


	&__pointer {
		background-color: $colorRankingPointer;
		border-radius: 100%;
		display: block;
		height: $indicatorPointerSpacing;
		// NOTE: the 1.5px are the space its needs to take from the 3px line wiches width is defined in js because its a canvas
		left: -$indicatorPointerSpacing / 2 + 1.5px;
		opacity: 0.7;
		position: absolute;
		top: - $indicatorPointerSpacing / 2;
		width: $indicatorPointerSpacing;
	}
}

// json-encode function
@import "sass-json-export/stylesheets/sass-json-export";

// stores a map as a json-encoded object inside a custom property and on a pseudo-element for old browsers
@mixin storeValuesForJs($map, $pseudoElement: before, $varName: jsValues, $propertyName: content) {
	// for old browsers not supporting custom properties
	&::#{$pseudoElement} {
		#{$propertyName}: json-encode($map);
		display: none;
	}
	@supports (--varName: 'whatever') {
		#{unquote('--') + $varName}: json-encode($map);
	}
}

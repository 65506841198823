
@mixin isp-modalContextBg() {
	&::before {
		background-color: $colorBgInverted;
		content: '';
		position: fixed;
		height: 100vh;
		width: 100%;
		top: 0;
		z-index: -1;
	}


	&::after {
		background-color: $colorBgInverted;
		content: '';
		height: $headerHeightSmall;
		position: fixed;
		width: 100%;
		top: 0;
		z-index: 10;

		@include media($smallLayout) {
			height: $headerHeightMedium;
		}
	}
}

.isp-page {
	position: relative;

	&__bgFigure {
		display: none;
	}


	&__bgImg {
		display: none;
	}


	&__blocks {
		position: relative;
	}


	&__menu {
		display: none;
	}
}

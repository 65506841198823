.isp-mapLegend {
	@include font(legend);
	margin-top: $base2;
	position: relative;

	@include media($mediumLayout) {
		margin-right: 30%;
	}

	@include media($largeLayout) {
		margin-right: 50%;
	}

	.isp-plot & {
		@include isp-subContainer();
		background-color: $colorBgBase;
		display: none;
		padding: $base;
		margin-bottom: $base2;
	}

	.isp-plot.isp-js-showMap & {
		display: block;
	}


	&__bar {
		height: 100%;
		flex-grow: 1;
		flex-shrink: 1;
		position: relative;

		&--negative {
			transform: scaleX(-1);
		}

		&--noData {
			display: inline-block;
			height: $base * 1.5;
			vertical-align: middle;
			width: $base6;
		}

		&--zero {
			flex-grow: 0;
			flex-shrink: 0;
			width: 2px;
		}
	}


	&__bars {
		display: flex;
		flex-direction: row;
		height: $base * 1.5;
		justify-content: space-between;
		position: relative;

		.isp-mapLegend.isp-js-directionAsc & {
			transform: scale(-1);
		}
	}


	&__noDataBar {
		position: relative;
		margin-bottom: $base2;
	}


	&__value {
		position: absolute;
		top: 0;
		// transform: translateX(-50%);

		&--negative {
			left: 0%;
		}

		&--positive {
			right: 0%;
		}

		&--zero {
			left: 50%;
			transform: translateX(-50%);
		}
	}


	&__values {
		height: getCalculatedLineHeight(detail);
		margin-top: $base;
		position: relative;
		width: 100%;
	}
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, 50% 50%);
*/
@mixin objectFit($fit: fill, $position: null, $setIeNoJsFallback: true) {
	object-fit: $fit;
	@if $position {
		object-position: $position;
		font-family: 'object-fit: #{$fit}; object-position: #{$position}';
	} @else {
		font-family: 'object-fit: #{$fit}';
	}

	@if $setIeNoJsFallback and $fit != fill {
		html.no-js & {
			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				box-sizing: border-box;
				// this hides the image loaded in the src
				padding-left: 100%;
				background-size: $fit;
				background-position: if($position, $position, 50% 50%);
			}
		}
	}
}

.isp-block {
	padding-top: $blockSpacing;

	&--countryDetails {
		// page-break-after: always;
		page-break-inside: avoid;
		// margin-top: 0;
	}

	&--cover {
		.isp-glossary__blocks & {
			display: none;
		}
	}

	&--miniConfigurator {
		display: none;
	}


	&--plot {
		padding-top: 0;
		// page-break-inside: avoid;
		// page-break-after: always;
		// page-break-before: always;
	}


	&--image {
		.isp-block--cover + & {
			padding-top: 0;
		}
	}


	&--pageMenu {
		display: none;
	}


	&__content {
		.isp-block__title + & {
			margin-top: $base2;
		}
	}


	&__text {
	}


	&__title {
		@include font(h2);
		width: 100%;

		.isp-page--home & {
			text-align: center;
		}

		.isp-page:not(.isp-page--home) .isp-block:not(.isp-block--cover):not(.isp-block--pageMenu) + .isp-block & {
			page-break-before: always;
		}
	}
}


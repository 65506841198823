.isp-configuratorSearch {
	background-color: $colorBgSearch;
	border-radius: $base2;
	color: $colorBase;
	display: flex;
	flex-direction: row;
	line-height: $base4;
	padding: 0 $base5 0 $base2;
	position: relative;


	&__cancel {
		@include ease(opacity visibility, $duration2);
		align-items: center;
		color: $colorSearchPlaceholder;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		height: 100%;
		line-height: 0;
		opacity: 0;
		position: absolute;
		right: $base2;
		top: 0;
		visibility: hidden;
		width: $base3;
		z-index: 3;

		.isp-configuratorSearch.isp-js-filled & {
			opacity: 1;
			visibility: inherit;
		}


		[data-type="svg"] {
			height: $base * 2.4;
			width: $base * 2.4;
		}
	}


	&__icon {
		align-items: center;
		color: $colorSearchPlaceholder;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		height: 100%;
		left: $base2;
		line-height: 0;
		position: absolute;
		top: 0;
		width: $base3;
		z-index: 1;

		[data-type="svg"] {
			height: $base * 2.4;
			width: $base * 2.4;
		}
	}


	&__input {
		@include font(configuratorControl);
		background-color: transparent;
		border: 0;
		flex-grow: 1;
		flex-shrink: 0;
		line-height: $base4;
		padding: 0 0 0 $base3;
		position: relative;
		z-index: 2;

		html.iOs & {
			font-size: pxToRem(16);
		}

		&::placeholder {
			color: $colorSearchPlaceholder;
		}
	}


	&__item {
		@include font(configuratorControl);
		border-bottom: 1px solid $colorBorderLight;
		cursor: pointer;
		display: none;
		height: auto;
		margin-bottom: $base;
		// allow enough room to store all the values in previous order for sorting them
		order: 1000;
		padding-bottom: $base;
		position: relative;
	}


	&__items {
		display: flex;
		flex-direction: column;
		margin-left: $base2;
		margin-right: $base2;
		padding-top: $base;

		@include media($largeLayout) {
			bottom: 0;
			left: 0;
			margin: 0;
			overflow: auto;
			padding-right: $base;
			position: absolute;
			right: 0;
			scrollbar-color: $colorScrollBarBgHandle $colorScrollBarBgTrack;
			scrollbar-width: thin;
			top: $base8;
			-webkit-overflow-scrolling: touch;


			&::-webkit-scrollbar {
				width: $customScrollbarWidth;
			}

			&::-webkit-scrollbar-track {
				background-color: $colorScrollBarBgTrack;
			}

			&::-webkit-scrollbar-thumb {
				background-color: $colorScrollBarBgHandle;
			}
		}
	}


	&__itemName {
		display: block;

		.isp-js-highlight {
			background-color: $colorBgSearchHighlight;
		}
	}


	&__itemTopic {
		color: $colorConfigurationActionDisabled;
		display: block;
	}
}

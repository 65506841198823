@mixin isp-actionLink($color: currentColor, $hoverColor: false) {
	@include font(action);
	color: $color;
	display: inline-block;
	padding-bottom: $baseHalf;
	position: relative;
	text-decoration: none;

	&::after {
		border-bottom: 1px solid $color;
		bottom: 0;
		content: '';
		left: 0;
		position: absolute;
		width: 100%;
	}

	@if ($hoverColor != false) {
		&:hover {
			color: $hoverColor;
		}

		&:hover::after {
			border-bottom: 1px solid $hoverColor;
		}
	}
}

.isp-configurator {
	@mixin notSelected() {
		.isp-configurator[data-isp-current-section="area"] .isp-configurator__section--type &,
		.isp-configurator[data-isp-current-section="topic"] .isp-configurator__section--type &,
		.isp-configurator[data-isp-current-section="type"] .isp-configurator__section--area &,
		.isp-configurator[data-isp-current-section="topic"] .isp-configurator__section--area &,
		.isp-configurator[data-isp-current-section="type"] .isp-configurator__section--topic &,
		.isp-configurator[data-isp-current-section="area"] .isp-configurator__section--topic & {
			@content;
		}
	}


	@include storeValuesForJs((largeLayout: false));
	height: 100%;
	padding: 0;
	position: relative;
	width: 100%;


	@include media($largeLayout) {
		@include storeValuesForJs((largeLayout: true));
		@include isp-container();
		padding-top: $configuratorSummaryBaseHeight;
		padding-bottom: $base2;
	}


	&__areaDecoration {
		@include ease(opacity visibility, $duration2);
		@include aspectRatio(1);
		display: none;
		position: relative;
		margin: $base6 $base10 auto ($base10 - $gutter);
		max-width: $base * 40;


		.isp-configurator__section.isp-js-hideContent & {
			opacity: 0;
			visibility: hidden;
		}

		.isp-configurator:not(.isp-js-selected) & {
			@include media($largeLayout) {
				display: block;
			}
		}
	}


	&__areaDecorationImg {
		left: 0;
		height: 100%;
		position: absolute;
		top: 0;
		width: 100%;
	}


	&__sectionDecorator {
		border-right: 1px solid $colorDecoration;
		bottom: 0;
		display: none;
		left: 0;
		pointer-events: none;
		position: absolute;
		top: $base4;
		width: staticColumnSize(1, 18, $gutter);
		z-index: 10;

		@include media($largeLayout) {
			display: block;
		}

		.isp-configurator__section.isp-js-hideDescription ~ & {
			@include ease(transform, $duration4);
			will-change: transform;
		}

		&--type {
			transform: translateX(5 * 100%) translateX(5 * $gutter);

			.isp-configurator[data-isp-current-section="type"] & {
				transform: translateX(13 * 100%) translateX(13 * $gutter);
			}

			.isp-configurator[data-isp-current-section="area"] &,
			.isp-configurator[data-isp-current-section="topic"] & {
				transform: translateX(1 * 100%) translateX(1 * $gutter);
			}
		}

		&--area {
			transform: translateX(11 * 100%) translateX(11 * $gutter);

			.isp-configurator[data-isp-current-section="type"] &,
			.isp-configurator[data-isp-current-section="area"] & {
				transform: translateX(15 * 100%) translateX(15 * $gutter);
			}

			.isp-configurator[data-isp-current-section="topic"] & {
				transform: translateX(3 * 100%) translateX(3 * $gutter);
			}
		}
	}


	&__sections {
		height: 100%;
		min-height: 100%;
		padding-right: $base3;
		position: relative;
		z-index: 1;

		.isp-configurator.isp-js-selected & {
			height: auto;

			@include media($largeLayout) {
				height: 100%;
			}
		}

		@include media($smallLayout) {
			padding-right: 0;
			width: 60%;
		}

		@include media($largeLayout) {
			@include mediaMap($sizeVariants, blockVerticalSpacing);
			@include staticColumns();
			height: 100%;
			min-height: auto;
			padding-right: 0;
			width: 100%;
		}
	}
}

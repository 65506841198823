.isp-configuratorSummary {
	@mixin addFillingLine($zIndex, $lineColor: $colorConfiguratorAction) {
		&::before {
			border-top: 1px solid $lineColor;
			content: '';
			height: 0;
			left: 0;
			position: absolute;
			top: getFontSize(detail) * getLineHeight(detail) / 2;
			width: 100%;
			z-index: $zIndex;

			@include media($smallLayout) {
				top: getFontSize(detail, $smallLayout) * getLineHeight(detail, $smallLayout) / 2;
			}
		}
	}


	@include ease(transform, $duration4);
	background-color: $colorBgAlternative;
	color: $colorAlternative;
	display: flex;
	flex-direction: column;
	height: calc(100vh - #{$headerHeightSmall});
	left: 0;
	padding: $base 0 $base2 $base5;
	position: fixed;
	top: $headerHeightSmall;
	transform: translateX(100%) translateX(-$base3);
	width: 100%;
	z-index: 2;

	@include media($smallLayout) {
		left: 60%;
		height: calc(100vh - #{$headerHeightMedium});
		padding-left: $base2;
		right: 0;
		top: $headerHeightMedium;
		transform: none;
		width: auto;
	}


	@include media($largeLayout) {
		background-color: transparent;
		display: block;
		flex-grow: 0;
		height: auto;
		left: 0;
		min-height: $configuratorSummaryBaseHeight;
		overflow: visible;
		padding: 0 $containerPaddingLarge $base2 $containerPaddingLarge;
		position: absolute;
		top: 0;
		transform: none;
		width: 100%;
	}

	&.isp-js-opened {
		transform: none;
	}


	&::before {
		@include media($largeLayout) {
			background-color: $colorBgAlternative;
			border-bottom: 1px solid $colorHighlightDisabled;
			bottom: 0;
			content: '';
			height: 200vh;
			left: 50%;
			margin-left: -50vw;
			position: absolute;
			width: 100vw;
			z-index: -1;
		}
	}


	&__createReport {
		flex-grow: 0;
		flex-shrink: 0;
		text-align: right;
		padding: $base2 $base2 $base5 $base2;

		@include media($largeLayout) {
			left: 0;
			bottom: 0;
			height: 0;
			padding: 0;
			position: absolute;
			text-align: center;
			width: 100%;
		}
	}


	&__createReportAction {
		position: relative;
		margin: auto;

		@include media($largeLayout) {
			transform: translateY(-50%);
		}
	}



	&__groupName {
		color: $colorConfiguratorAction;
		flex-grow: 0;
	}


	&__groupValue {
		@include addFillingLine(-1);
		flex-grow: 1;
		margin-left: 0;
		margin-top: $base;
		min-height: getFontSize(detail) * getLineHeight(detail);
		text-indent: 0;
		position: relative;

		@include media($largeLayout) {
			margin-left: $base;
			margin-top: 0;
			min-height: auto;
		}

		&--indicator {
			@include media($largeLayout) {
				@include ease(height, $duration2);
				overflow: hidden;
			}
		}
	}


	&__groupValueList {
		&--otherIndicators {
			margin-top: $base;

			@include media($largeLayout) {
				@include ease(opacity visibility, $duration2);
				margin-top: 0;
				opacity: 0;
				position: absolute;
				visibility: hidden;
				width: 100%;
			}

			.isp-configuratorSummary.isp-js-expanded & {
				@include media($largeLayout) {
					opacity: 1;
					position: relative;
					visibility: inherit;
				}
			}
		}
	}


	&__listToggler {
		@include addFillingLine(0, $colorHighlight);
		align-items: flex-start;
		background-color: $colorBgAlternative;
		color: $colorHighlight;
		display: none;
		font-weight: 600;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		position: relative;
		text-decoration: none;
		text-transform: uppercase;

		.isp-configuratorSummary.isp-js-indicatorsSplitted & {
			@include media($largeLayout) {
				display: flex;
			}
		}
	}


	&__listTogglerIcon {
		background-color: $colorBgAlternative;
		display: flex;
		flex-direction: column;
		height: getFontSize(detail) * getLineHeight(detail);
		flex-grow: 0;
		justify-content: center;
		margin-left: $base * 1.5;
		order: 3;
		padding-left: $baseHalf;
		position: relative;
		z-index: 1;

		@include media($smallLayout) {
			height: getFontSize(detail, $smallLayout) * getLineHeight(detail, $smallLayout);
		}


		[data-type="svg"] {
			@include ease(transform, $duration2);
			color: inherit;
			position: relative;

			.isp-configuratorSummary.isp-js-expanded & {
				transform: rotate(180deg);
			}
		}
	}


	&__listTogglerLabel {
		background-color: inherit;
		position: relative;
		flex-grow: 0;
		outline: $baseHalf solid $colorBgAlternative;
		order: 1;
		z-index: 1;

		&--show {
			.isp-configuratorSummary.isp-js-expanded & {
				display: none;
			}
		}

		&--hide {
			.isp-configuratorSummary:not(.isp-js-expanded) & {
				display: none;
			}
		}
	}


	&__section {
		@include font(detail);

		& + & {
			margin-top: $base2;

			@include media($largeLayout) {
				margin-top: 0;
			}
		}

		@include media($largeLayout) {
			display: flex;
			flex-direction: row;
			width: staticColumnSize(6, 18, $gutter);
		}


		&--indicator {
			@include media($largeLayout) {
				margin-left: staticColumnPush(12, 18, $gutter);
			}
		}


		&::after {
			@include ease(transform, $duration5);
			background-color: $colorHighlight;
			bottom: $configuratorLineIndicatorOffset;
			content: '';
			display: none;
			height: $configuratorLineIndicatorHeight;
			position: absolute;
			transform: scaleX(0);
			width: staticColumnPush(-3, 18, $gutter, 50vw);

			@include media($largeLayout) {
				display: block;
			}
		}

		&.isp-js-selected::after {
			transform: scaleX(1);
		}

		&--type::after {
			right: staticColumnPush(12, 18, $gutter);
			transform-origin: 0% 50%;
		}

		&--area::after {
			left: staticColumnSize(6, 18, $gutter);
			transform-origin: 50% 50%;
			// 2.1 to fix rounding issues
			width: staticColumnSize(6, 18, $gutter, $gutter * 2.1);
		}

		&--topic::after {
			display: none;

			@include media($largeLayout) {
				display: none;
			}
		}

		&--indicator::after {
			left: staticColumnPush(12, 18, $gutter);
			transform-origin: 100% 50%;
		}
	}


	&__sections {
		left: 0;
		top: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		overflow: auto;
		padding-right: $base2;
		-webkit-overflow-scrolling: touch;

		@include media($smallLayout) {
			padding-right: $containerPaddingMedium;
		}


		@include media($largeLayout) {
			@include staticColumns($vSpace: 0);
			height: auto;
			left: auto;
			top: auto;
			overflow: visible;
			padding-right: 0;
			padding-bottom: 0;
			position: static;
		}
	}


	&__sectionsContainer {
		flex-grow: 1;
		flex-shrink: 0;
		position: relative;

		@include media($largeLayout) {
			padding: 0;
			position: static;
		}
	}


	&__title {
		@include font(base);
		font-weight: 600;
		flex-grow: 0;
		flex-shrink: 0;
		padding-bottom: $base;

		@include media($largeLayout) {
			display: none;
		}
	}

	&__toggler {
		@include ease(background-color, $duration4);
		background-color: $colorBgAlternative;
		left: 0;
		height: 100%;
		position: absolute;
		text-align: center;
		top: 0;
		white-space: nowrap;
		width: $base3;

		@include media($smallLayout) {
			display: none;
		}

		.isp-configuratorSummary.isp-js-ready & {
			background-color: $colorHighlight;
		}

		.isp-configuratorSummary.isp-js-opened & {
			background-color: $colorBgBase;
		}
	}


	&__togglerLabel {
		@include ease(opacity, $duration4);
		@include font(configuratorControl);
		left: 0;
		line-height: $base3;
		height: 100%;
		opacity: 1;
		position: absolute;
		top: 1px;
		width: 100%;
		writing-mode: vertical-rl;
		z-index: 2;

		&--open {
			.isp-configuratorSummary.isp-js-opened & {
				opacity: 0;
			}
		}

		&--close {
			color: $colorBase;
			opacity: 0;

			.isp-configuratorSummary.isp-js-opened & {
				opacity: 1;
			}
		}


		[data-type="svg"] {
			margin-top: $base;
			position: relative;
		}

		&--close [data-type="svg"] {
			transform: rotate(180deg);
		}
	}



	&__value {
		@include addFillingLine(0);
		background-color: $colorBgAlternative;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: flex-start;
		position: relative;

		& + & {
			margin-top: $base;

			@include media($largeLayout) {
				margin-top: 0;
			}
		}
	}


	&__valueRemove {
		background-color: $colorBgAlternative;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		height: getFontSize(detail) * getLineHeight(detail);
		flex-grow: 0;
		justify-content: center;
		margin-left: $base * 1.5;
		order: 3;
		padding-left: $baseHalf;
		position: relative;
		z-index: 1;

		@include media($smallLayout) {
			height: getFontSize(detail, $smallLayout) * getLineHeight(detail, $smallLayout);
		}


		[data-type="svg"] {
			color: $colorConfiguratorAction;
		}
	}


	&__valueText {
		position: relative;
		flex-grow: 0;
		order: 1;
		z-index: 1;
	}


	&__valueTextWrapper {
		background-color: $colorBgAlternative;
		outline: $baseHalf solid $colorBgAlternative;
	}
}

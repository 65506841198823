.isp-gallery {
	@include isp-subContainer($pushing: true);
	width: staticColumnSize(12, 12, $gutter);

	@include media($mediumLayout) {
		columns: 2;
		column-gap: $gutter;
	}

	&__caption {
		@include font(detail);
		color: $colorImgCaption;
		position: absolute;

		bottom: - getFontSize(detail) * getLineHeight(detail);

		@include media($smallLayout) {
			bottom: - getFontSize(detail, $smallLayout) * getLineHeight(detail, $smallLayout);
		}

	}

	&__image {
		display: inline-block;
		margin-bottom: $gutter;
		position: relative;
		width: 100%;
	}

	&__img {
		width: 100%;
	}
}

.isp-header {
	display: block;


	&__logoImg {
		height: 1.5cm;
		width: auto;
	}


	&__logoLink {
		color: $colorBase;
		line-height: 0;
		display: block;
		text-decoration: none;
	}


	&__logoType {
		display: inline-block;
		height: 100%;
		vertical-align: middle;

		[data-type="svg"] {
			height: 100%;
			width: auto;
		}
	}


	&__nav {
		display: none;
	}
}

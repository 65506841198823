.isp-glossary {
	color: $colorInverted;


	&__container {
		@include isp-modalContextContainer();
		padding-left: 0;
	}


	&__content {
		@include isp-modalContextBg();
		position: relative;
		min-height: 140vh;

		@include media($smallLayout) {
			margin-left: staticColumnPush(4, 12, $gutter);
			// NOTE: blocks has 40px padding by default, so adding 20px to get total 60px distance
			padding: $base2 0;
		}

		@include media($largeLayout) {
			margin-left: staticColumnPush(3, 12, $gutter);
		}


		&::after {

			@include media($smallLayout) {
				background-color: $colorBgInverted;
			}
		}


	}

	&__loadingDummy {
		@include ease(visibility opacity, $overlayDuration);

		color: $colorHighlight;
		font-weight: bold;
		left: 50%;
		position: absolute;
		top: 50vh;
		transform: translateX(-50%);

		.isp-glossary__content.isp-js-loaded & {
			@include fadeOut();
		}
	}
}

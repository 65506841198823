.isp-configuratorSection {
	@mixin notSelected() {
		.isp-configurator[data-isp-current-section="area"] .isp-configuratorSection--type &,
		.isp-configurator[data-isp-current-section="topic"] .isp-configuratorSection--type &,
		.isp-configurator[data-isp-current-section="type"] .isp-configuratorSection--area &,
		.isp-configurator[data-isp-current-section="topic"] .isp-configuratorSection--area &,
		.isp-configurator[data-isp-current-section="type"] .isp-configuratorSection--topic &,
		.isp-configurator[data-isp-current-section="area"] .isp-configuratorSection--topic & {
			@content;
		}
	}

	@function getSectionHeaderHeight($variant: '') {
		@return getCalculatedLineHeight(h2, $variant) + $base2;
	}

	background-color: $colorBgBase;
	display: flex;
	flex-direction: column;
	height: calc(#{100% / 3} - 1px);
	padding: 0;
	position: sticky;
	z-index: 5;


	.isp-configurator:not(.isp-js-selected) &,
	.isp-configurator[data-isp-current-section="area"] &--type,
	.isp-configurator[data-isp-current-section="topic"] &--type,
	.isp-configurator[data-isp-current-section="type"] &--area,
	.isp-configurator[data-isp-current-section="topic"] &--area,
	.isp-configurator[data-isp-current-section="type"] &--topic,
	.isp-configurator[data-isp-current-section="area"] &--topic {
		cursor: pointer;
	}


	@include media($largeLayout) {
		flex-grow: 0;
		height: 100%;
		// //needed to override inline style set in a different media query
		// // stylelint-disable-next-line
		// min-height: auto !important;
		// overflow: hidden;
		position: relative;
		width: staticColumnSize(6, 18, $gutter);
	}

	&--type {
		top: $headerHeightSmall;

		@include media($smallLayout) {
			top: $headerHeightMedium;
		}

		@include media($largeLayout) {
			top: auto;
		}

		.isp-configurator[data-isp-current-section="type"] & {
			height: auto;
			position: relative;
			top: auto;
			z-index: 2;

			@include media($largeLayout) {
				height: 100%;
				top: auto;
				width: staticColumnSize(14, 18, $gutter);
			}
		}

		.isp-configurator[data-isp-current-section="area"] &,
		.isp-configurator[data-isp-current-section="topic"] & {
			height: getSectionHeaderHeight();

			@include media($smallLayout) {
				height: getSectionHeaderHeight($smallLayout);
			}

			@include media($largeLayout) {
				height: 100%;
				width: staticColumnSize(2, 18, $gutter);
			}
		}


		.isp-configurator.isp-js-selected & {
			@include media($largeLayout) {
				@include staticColumns();
			}
		}
	}

	&--area {
		top: (100% / 3);

		@include media($largeLayout) {
			top: auto;
		}

		.isp-configurator[data-isp-current-section="type"] & {
			bottom: getSectionHeaderHeight();
			height: getSectionHeaderHeight();
			top: auto;

			@include media($smallLayout) {
				bottom: getSectionHeaderHeight($smallLayout);
				height: getSectionHeaderHeight($smallLayout);
			}

			@include media($largeLayout) {
				bottom: auto;
				height: 100%;
				top: auto;
				width: staticColumnSize(2, 18, $gutter);
			}
		}

		.isp-configurator[data-isp-current-section="area"] & {
			bottom: auto;
			height: auto;
			top: auto;
			position: relative;
			z-index: 2;

			@include media($largeLayout) {
				bottom: auto;
				height: 100%;
				top: auto;
				width: staticColumnSize(14, 18, $gutter);
			}
		}

		.isp-configurator[data-isp-current-section="topic"] & {
			bottom: auto;
			height: getSectionHeaderHeight();
			top: $headerHeightSmall + getSectionHeaderHeight();

			@include media($smallLayout) {
				top: $headerHeightMedium + getSectionHeaderHeight($smallLayout);
				height: getSectionHeaderHeight($smallLayout);
			}

			@include media($largeLayout) {
				bottom: auto;
				height: 100%;
				top: auto;
				width: staticColumnSize(2, 18, $gutter);
			}
		}

		.isp-configurator.isp-js-selected & {
			@include media($largeLayout) {
				display: flex;
				flex-direction: column;
			}
		}
	}

	&--topic {
		bottom: 0;

		@include media($largeLayout) {
			bottom: auto;
		}

		.isp-configurator[data-isp-current-section="type"] &,
		.isp-configurator[data-isp-current-section="area"] & {
			height: getSectionHeaderHeight();

			@include media($smallLayout) {
				height: getSectionHeaderHeight($smallLayout);
			}

			@include media($largeLayout) {
				height: 100%;
				width: staticColumnSize(2, 18, $gutter);
			}
		}

		.isp-configurator[data-isp-current-section="topic"] & {
			bottom: auto;
			height: auto;
			position: relative;
			z-index: 2;

			@include media($largeLayout) {
				bottom: auto;
				height: 100%;
				width: staticColumnSize(14, 18, $gutter);
			}
		}

		.isp-configurator.isp-js-selected & {
			@include media($largeLayout) {
				@include staticColumns();
			}
		}
	}


	.isp-configurator:not(.isp-js-selected) &::before,
	.isp-configurator[data-isp-current-section="area"] &--type::before,
	.isp-configurator[data-isp-current-section="topic"] &--type::before,
	.isp-configurator[data-isp-current-section="type"] &--area::before,
	.isp-configurator[data-isp-current-section="topic"] &--area::before,
	.isp-configurator[data-isp-current-section="type"] &--topic::before,
	.isp-configurator[data-isp-current-section="area"] &--topic::before {
		@include ease(opacity, $duration2);
		content: '';
		display: none;
		// background-size: 100% autoye;
		// background-position: 50% 50%;
		// background-repeat: no-repeat;
		background-image: $configuratorHoverBg;
		top: -$base4;
		bottom: -$base2;
		left: 0;
		right: 0;
		opacity: 0;
		position: absolute;
		z-index: 0;


		@include media($largeLayout) {
			display: block;
		}
	}

	.isp-configurator &:hover::before {
		opacity: 1;
	}


	&__content {
		@include ease(opacity visibility, $duration2);
		display: none;
		flex-grow: 1;
		flex-shrink: 1;
		// margin-right: $base;
		opacity: 0;
		position: relative;
		visibility: hidden;
		// width: 100%;
		// left: 0;


		@include media($largeLayout) {
			display: block;
			opacity: 1;
			visibility: inherit;
			z-index: 3;
		}

		.isp-configuratorSection.isp-js-beforeShowContent & {
			display: block;
		}

		.isp-configuratorSection.isp-js-duringShowContent & {
			opacity: 1;
			visibility: inherit;
		}

		.isp-configuratorSection.isp-js-hideContent & {
			@include media($largeLayout) {
				opacity: 0;
				visibility: hidden;
			}
		}

		.isp-configurator.isp-js-selected .isp-configuratorSection--type &,
		.isp-configurator.isp-js-selected .isp-configuratorSection--topic & {
			@include media($largeLayout) {
				@include staticColumns();
				width: staticColumnSize(9, 14, $gutter);
			}
		}

		.isp-configurator.isp-js-selected .isp-configuratorSection--area & {
			flex-grow: 1;

			@include media($largeLayout) {
				background: $colorBgConfiguratorArea;
				width: staticColumnSize(5, 14, $gutter, -$base);
			}
		}

		@include notSelected() {
			@include media($largeLayout) {
				display: none;
			}
		}


		&::after {
			.isp-configurator.isp-js-selected .isp-configuratorSection--area & {
				@include media($largeLayout) {
					background-color: $colorBgBase;
					content: '';
					left: 0;
					height: $base2;
					position: absolute;
					top: 100%;
					width: 100%;
				}
			}
		}
	}


	&__controls {
		display: flex;
		flex-direction: column;

		&::-webkit-scrollbar {
			width: $customScrollbarWidth;
		}

		&::-webkit-scrollbar-track {
			background-color: $colorScrollBarBgTrack;
		}

		&::-webkit-scrollbar-thumb {
			background-color: $colorScrollBarBgHandle;
		}

		@include media($largeLayout) {
			scrollbar-color: $colorScrollBarBgHandle $colorScrollBarBgTrack;
			scrollbar-width: thin;
		}

		&--area {
			@include media($largeLayout) {
				bottom: 0;
				left: 0;
				overflow-x: hidden;
				overflow-y: auto;
				padding-right: $base;
				position: absolute;
				top: $base2;
				width: 100%;
				-webkit-overflow-scrolling: touch;
			}

			.isp-configurator:not(.isp-js-selected) & {
				@include media($largeLayout) {
					display: none;
				}
			}

			// push the World item always to the bottom, even if the list is short
			&::before {
				content: '';
				display: block;
				flex-grow: 1;
				order: 1001;
			}
		}

		&--indicator {
			@include ease(opacity visibility, $duration4);
			position: relative;
			width: 100%;

			&.isp-js-collapsed {
				opacity: 0;
				position: absolute;
				visibility: hidden;

				@include media($largeLayout) {
					opacity: 1;
					visibility: inherit;
				}
			}

			@include media($largeLayout) {
				bottom: 0;
				left: 0;
				overflow: auto;
				padding-right: $base;
				padding-top: $base;
				position: absolute;
				top: getCalculatedLineHeight(base) + $base;
				width: 100%;
				-webkit-overflow-scrolling: touch;
			}

			.isp-configurator:not(.isp-js-selected) & {
				@include media($largeLayout) {
					display: none;
				}
			}
		}

		&--type {
			@include media($largeLayout) {
				bottom: 0;
				left: 0;
				overflow: auto;
				padding-right: $base;
				position: absolute;
				top: getCalculatedLineHeight(base) + $base;
				width: staticColumnSize(4, 9, $gutter);
				-webkit-overflow-scrolling: touch;
			}

			.isp-configurator:not(.isp-js-selected) & {
				@include media($largeLayout) {
					top: $base3;
					right: $base;
					width: auto;
				}
			}
		}

		&--topic {
			@include ease(opacity visibility, $duration4);
			padding-bottom: $base;
			position: relative;
			width: 100%;

			&.isp-js-collapsed {
				opacity: 0;
				position: absolute;
				visibility: hidden;

				@include media($largeLayout) {
					opacity: 1;
					visibility: inherit;
				}
			}

			@include media($largeLayout) {
				bottom: 0;
				left: 0;
				overflow: auto;
				padding-bottom: 0;
				padding-right: $base;
				position: absolute;
				top: getCalculatedLineHeight(base) + $base;
				width: 100%;
				-webkit-overflow-scrolling: touch;
			}

			.isp-configurator:not(.isp-js-selected) & {
				@include media($largeLayout) {
					display: flex;
					justify-content: flex-start;
					flex-direction: row;
					flex-wrap: wrap;
					align-items: top;
					align-content: flex-start;
					top: $base3;
				}
			}
		}
	}


	&__controlsContainer {
		padding: $base2;

		@include media($smallLayout) {
			padding-left: $containerPaddingMedium;
		}

		@include media($largeLayout) {
			height: 100%;
			// display: none;
			left: 0;
			overflow: hidden;
			padding: $base3 $base 0 0;
			position: absolute;
			width: 100%;
		}



		.isp-configurator.isp-js-selected & {
			@include media($largeLayout) {
				display: block;
				padding-top: 0;
			}
		}

		&--type {
			.isp-configurator.isp-js-selected & {
				@include media($largeLayout) {
					width: 100%;
				}
			}
		}

		&--area {
			.isp-configurator:not(.isp-js-selected) & {
				@include media($largeLayout) {
					display: none;
				}
			}

			.isp-configurator.isp-js-selected & {
				@include media($largeLayout) {
					bottom: 0;
					top: $base6;
					height: auto;
					width: 100%;
				}
			}
		}

		&--topic {
			@include ease(height, $duration4);
			overflow: hidden;
			padding-bottom: 0;
			position: relative;

			@include media($largeLayout) {
				position: absolute;
				transition: none;
			}

			.isp-configurator.isp-js-selected & {
				@include media($largeLayout) {
					width: staticColumnSize(4, 9, $gutter);
				}
			}
		}

		&--indicator {
			@include ease(height, $duration4);
			overflow: hidden;
			padding-top: 0;
			position: relative;

			@include media($largeLayout) {
				padding-top: $base3;
				position: absolute;
				transition: none;
			}

			.isp-configurator.isp-js-selected & {
				@include media($largeLayout) {
					left: auto;
					right: 0;
					width: staticColumnSize(5, 9, $gutter);
				}
			}
		}
	}


	&__controlsLabel {
		@include font(base);
		font-weight: 600;
		display: none;
		margin-bottom: $base;

		&--topic {
			display: flex;
			flex-direction: row;

			@include media($largeLayout) {
				display: none;
				pointer-events: none;
			}

			.isp-configurator.isp-js-selected & {
				@include media($largeLayout) {
					display: block;
				}
			}
		}

		&--indicator {
			border-top: 1px solid $colorInputBase;
			display: flex;
			flex-direction: row;
			padding-top: $base;

			@include media($largeLayout) {
				border: 0;
				display: none;
				padding-top: 0;
				pointer-events: none;
			}

			.isp-configurator.isp-js-selected & {
				@include media($largeLayout) {
					display: block;
				}
			}

			&.isp-js-disabled {
				opacity: 0.5;
				pointer-events: none;

				@include media($largeLayout) {
					opacity: 1;
				}
			}
		}

		.isp-configurator.isp-js-selected & {
			@include media($largeLayout) {
				display: block;
			}
		}
	}


	&__controlsLabelIcon {
		@include ease(transform, $duration4);
		color: $colorDecoration;
		flex-grow: 0;
		flex-shrink: 0;
		line-height: 0;
		position: relative;
		transform: rotate(180deg);


		.isp-configuratorSection__controlsLabel.isp-js-toggled & {
			transform: none;
		}

		[data-type="svg"] {
			height: $base * 2.4;
			width: $base * 2.4;
		}

		@include media($largeLayout) {
			display: none;
		}
	}


	&__controlsLabelSlot {
		@include font(configuratorControl);
		display: block;
		font-weight: 400;
		flex-grow: 1;
		flex-shrink: 0;
		margin-left: $base;
		// margin-top: 0.5em;
		overflow: hidden;
		text-overflow: ellipsis;
		visibility: hidden;
		white-space: nowrap;

		@include media($largeLayout) {
			display: none;
		}

		.isp-configuratorSection__controlsLabel.isp-js-toggled:not(.isp-js-empty) & {
			visibility: inherit;
		}
	}


	&__controlsLabelValue {
		@include font(configuratorControl);
		font-weight: 600;
		flex-grow: 0;
		flex-shrink: 0;

		.isp-configuratorSection__controlsLabel--indicator & {
			flex-grow: 1;
		}

		&--default {
			display: none;

			@include media($largeLayout) {
				display: block;
			}
		}


		&--alternative {
			@include media($largeLayout) {
				display: none;
			}
		}
	}


	&__description {
		@include font(detail);
		@include ease(opacity visibility, $duration2);
		padding: 0 $base2;

		@include media($smallLayout) {
			padding-left: $containerPaddingMedium;
		}

		@include media($largeLayout) {
			padding: 0;
		}

		.isp-configurator[data-isp-current-section="area"] .isp-configuratorSection--area & {
			@include media($largeLayout) {
				background: $colorBgConfiguratorArea;
				padding-right: $base2;
			}
		}

		.isp-configuratorSection.isp-js-hideDescription & {
			opacity: 0;
			visibility: hidden;
		}

		.isp-configuratorSection.isp-js-removeDescription & {
			display: none;
		}

		@include notSelected() {
			@include media($largeLayout) {
				display: none;
			}
		}
	}


	&__header {
		flex-grow: 0;
		flex-shrink: 0;
		// padding-right: $base;
		// padding-bottom: $base3;

		@include media($largeLayout) {
			padding-right: $base;
			position: relative;
			z-index: 2;
		}

		.isp-configurator[data-isp-current-section="type"] .isp-configuratorSection--type & {
			@include media($largeLayout) {
				width: staticColumnSize(5, 14, $gutter);
			}
		}

		.isp-configurator[data-isp-current-section="area"] .isp-configuratorSection--area & {
			@include media($largeLayout) {
				width: staticColumnSize(5, 14, $gutter);
				flex-grow: 0;
			}
		}

		.isp-configurator[data-isp-current-section="topic"] .isp-configuratorSection--topic & {
			@include media($largeLayout) {
				width: staticColumnSize(5, 14, $gutter);
				display: flex;
				flex-direction: column;
			}
		}
	}


	&__map {
		display: none;
		z-index: 1;

		.isp-configurator.isp-js-selected & {
			@include media($largeLayout) {
				@include ease(opacity visibility, $duration2);
				display: block;
			}
		}


		.isp-configuratorSection.isp-js-hideDescription & {
			@include media($largeLayout) {
				opacity: 0;
				visibility: hidden;
			}
		}

		@include notSelected() {
			@include media($largeLayout) {
				display: none;
			}
		}
	}


	&__search {
		flex-grow: 0;
		flex-shrink: 0;
		height: $base4;
		margin: $base2 $base2 0 $base2;


		&--area {
			@include media($largeLayout) {
				margin: $base2 $base2 0 0;
			}
		}


		&--indicator {
			@include media($largeLayout) {
				margin-left: 0;
				// margin-right: 0;
			}
		}


		.isp-configurator:not(.isp-js-selected) & {
			@include media($largeLayout) {
				display: none;
			}
		}
	}


	&__searchContainer {
		@include ease(opacity visibility, $duration2);
		height: auto;
		position: relative;

		@include media($largeLayout) {
			flex-grow: 1;
		}

		.isp-configuratorSection.isp-js-hideDescription & {
			opacity: 0;
			visibility: hidden;
		}

		.isp-configuratorSection.isp-js-removeDescription & {
			display: none;
		}

		@include notSelected() {
			@include media($largeLayout) {
				display: none;
			}
		}
	}


	&__typeDescription {
		@include font(detail);
		@include ease(opacity visibility, $duration2);
		left: 0;
		opacity: 0;
		padding-top: $base;
		position: absolute;
		top: 0;
		visibility: hidden;
		width: 100%;

		&.isp-js-selected {
			opacity: 1;
			visibility: inherit;
		}
	}


	&__typeDescriptions {
		display: none;

		@include media($largeLayout) {
			bottom: 0;
			left: staticColumnPush(4, 9, $gutter);
			position: absolute;
			top: 0;
			width: staticColumnSize(5, 9, $gutter, -$base);
		}

		.isp-configurator.isp-js-selected & {
			@include media($largeLayout) {
				display: block;
			}
		}
	}


	&__typeDescriptionsItems {
		&::-webkit-scrollbar {
			width: $customScrollbarWidth;
		}

		&::-webkit-scrollbar-track {
			background-color: $colorScrollBarBgTrack;
		}

		&::-webkit-scrollbar-thumb {
			background-color: $colorScrollBarBgHandle;
		}

		@include media($largeLayout) {
			scrollbar-color: $colorScrollBarBgHandle $colorScrollBarBgTrack;
			scrollbar-width: thin;
			bottom: 0;
			left: 0;
			overflow: auto;
			padding-right: $base;
			position: absolute;
			right: 0;
			top: getCalculatedLineHeight(base) + $base;
			-webkit-overflow-scrolling: touch;
		}
	}


	&__typeDescriptionsLabel {
		@include font(base);
		font-weight: 600;
		margin-bottom: $base;
	}


	&__title {
		@include font(h2);
		align-items: center;
		background-color: $colorBgBase;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		padding: $base $base2;
		position: relative;
		white-space: nowrap;

		@include media($smallLayout) {
			padding-left: $containerPaddingMedium;
		}

		@include media($largeLayout) {
			@include ease(opacity visibility, $duration2);
			background-color: transparent;
			padding: 0 0 $base 0;
		}

		.isp-configurator[data-isp-current-section="area"] .isp-configuratorSection--area & {
			@include media($largeLayout) {
				background: $colorBgConfiguratorArea;
			}
		}

		@include notSelected() {
			@include media($largeLayout) {
				flex-direction: column;
				align-items: flex-start;
				writing-mode: vertical-rl;
			}
		}

		&.isp-js-animate {
			@include ease(transform, $duration4);
		}

		.isp-configuratorSection.isp-js-hideTitle & {
			@include media($largeLayout) {
				opacity: 0;
				visibility: hidden;
			}
		}


		&::before {
			content: '';
			height: 0;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			border-top: 1px solid $colorDecoration;

			.isp-configuratorSection--type & {
				opacity: 0;
			}

			@include media($largeLayout) {
				display: none;
				border: 0;
			}


			.isp-configurator[data-isp-current-section="area"] .isp-configuratorSection--area & {
				@include media($largeLayout) {
					background-color: $colorBgConfiguratorArea;
					bottom: 100%;
					content: '';
					display: block;
					left: 0;
					height: $base4;
					position: absolute;
					top: auto;
					width: 100%;
				}
			}
		}


		&::after {
			content: '';
			height: 0;
			left: 0;
			opacity: 0;
			position: absolute;
			bottom: -1px;
			width: 100%;
			border-top: 1px solid $colorDecoration;

			@include media($largeLayout) {
				display: none;
			}
		}

		&.isp-js-closeDown::after {
			opacity: 1;
		}
	}


	&__titleIcon {
		@include ease(opacity visibility, $duration2);
		color: $colorHighlight;
		display: block;
		height: $base * 2.4;
		line-height: 0;
		margin-left: $baseHalf;
		position: relative;
		transform: rotate(90deg);
		width: $base * 2.4;

		@include media($largeLayout) {
			margin-left: $base;
			margin-top: $baseHalf;
			transform: none;
		}

		.isp-configuratorSection.isp-js-hideTitle & {
			opacity: 0;
			visibility: hidden;

			@include media($largeLayout) {
				opacity: 1;
				visibility: inherit;
			}
		}

		.isp-configurator.isp-js-selected & {
			display: none;
		}


		[data-type="svg"] {
			width: 100%;
			height: auto;
		}
	}


	&__titleLabel {
		@include ease(opacity visibility, $duration2);

		.isp-configurator.isp-js-firstSelection .isp-configuratorSection.isp-js-hideTitle & {
			opacity: 0;
			visibility: hidden;

			@include media($largeLayout) {
				opacity: 1;
				visibility: inherit;
			}
		}

		.isp-configurator:not(.isp-js-selected) .isp-configuratorSection:hover & {
			@include media($largeLayout) {
				color: $colorPageMenuHover;
			}
		}
	}


	&__titlePrefix {
		color: $colorHighlight;
		font-family: $fontFamilyMain;
		font-weight: 300;

		@include onHover('.isp-configuratorSection', '&') {
			@include media($largeLayout) {
				color: $colorHighlightHover;
			}
		}

		.isp-configurator[data-isp-current-section] & {
			display: none;
		}
	}


	&__titleSlot {
		@include ease(opacity visibility, $duration2);
		@include font(detail);
		display: none;
		margin-left: $base;
		margin-top: 0.5em;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		@include media($largeLayout) {
			margin: 0;
			white-space: normal;
			text-overflow: clip;
		}

		&.isp-js-empty,
		.isp-configuratorSection__title.isp-js-empty & {
			color: $colorWarning;
		}

		.isp-configuratorSection.isp-js-hideTitle & {
			opacity: 0;
			visibility: hidden;
		}

		@include notSelected() {
			display: block;
		}
	}
}

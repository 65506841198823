.isp-plot {
	padding-top: $base;
	position: relative;


	&__container {
		position: relative;
	}


	&__core {
		--plot-dot-size: 5;
		--plot-dot-hover-size: 5;
		--plot-dot-border-width: 0.0001; // 0 doesn’t seem to turn it off :-(
		--plot-font-size: 16; // no units
		// background-color: #F1F4F4;
		color: $colorBase;
		position: relative;
		// height: 70vh;
		// width: 100%;
		height: auto;
		margin-bottom: $base2;


		canvas {
			width: 100%;
		}
	}


	&__dataLinks {
		display: none;
	}


	&__downloadButton {
		display: none;
	}


	&__expandButton {
		display: none;
	}


	&__viewsContainer {
		position: relative;
	}
}

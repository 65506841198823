@mixin isp-link($color: $colorBase, $colorHover: $colorLinkHover) {
	@include ease(color, $hoverDuration);
	color: $color;
	@include font('action');
	text-decoration-line: none;
	border-bottom: 1px $color solid;
	padding-bottom: $base1 / 2;

	.isp-html.ie & {
		// NOTE: text-decoration-line not supported by IE.  ¯\_(ツ)_/¯
		text-decoration: none;
	}

	.isp-page--withBg & {
		color: $colorInverted;
		border-bottom: 1px $colorInverted solid;
	}

	@include onHover() {
		color: $colorHover;
		border-bottom: 1px $colorHover solid;
	}
}

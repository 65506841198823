.isp-temporaryTextLink {

	&__content {
		width: staticColumnSize(12, 12, $gutter);


		@include media($smallLayout) {
			margin-right: $gutter;
			width: staticColumnSize(6, 12, $gutter);
		}
	}


	&__title {
		@include font(h3);
		margin-bottom: $base1;

		@include media($smallLayout) {
			margin-bottom: $base2;
		}
	}


	&__link {
		display: flex;
		justify-content: center;
		margin-top: $base1;
		width: staticColumnSize(12, 12, $gutter);

		@include media($smallLayout) {
			@include clearfix();
			float: right;
			width: staticColumnSize(6, 12, $gutter);
		}
	}
}


.isp-linksReference {
	&__item {
		padding-left: $base;


		&::first-letter {
			text-transform: uppercase;
		}
	}


	&__items {
		@include font(detail);
		list-style-type: decimal;
		padding-left: $base5;
	}


	&__itemLabel {
		font-weight: 600;


		&::after {
			content: ':';
			margin-right: 0.2em;
		}
	}
}

// .isp-page__blocks & {
// 	// page
// }

// .isp-glossary__blocks & {
// 	// overlay
// }

.isp-glossaryList {

	padding-left: $base2;

	.isp-page__blocks & {
		@include isp-container();
		color: $colorBase;
		padding-right: $glossarySideSpacing;
	}

	.isp-glossary__blocks & {
		@include media($smallLayout) {
			padding-left: 0;
			padding-right: 0;
		}
	}


	&__content {
		flex-grow: 1;
		order: 1;
		position: relative;
		width: staticColumnSize(10, 12, $gutter);

		.isp-page__blocks & {
			@include media($smallLayout) {
				min-height: unset;
				width: 100%;
			}
		}

		.isp-glossary__blocks & {
			@include media($mediumLayout) {
				width: 100%;
			}
		}

		@include media($smallLayout) {
			order: 2;
		}
	}


	&__filter {
		$navHeightMedium: round(getFontSize(detailVariant, $smallLayout) * getLineHeight(detailVariant, $smallLayout) + $base2);

		display: block;
		position: sticky;
		top: $headerHeightSmall;
		margin-left: -$base4;
		padding-left: $base4;
		z-index: 3;

		&::before {
			content: '';
			position: absolute;
			bottom: -$base4;
			left: - $base3;
			width: calc(100% + #{$base3});
			height: $base4;
			background-repeat: repeat-x;
		}

		.isp-page__blocks & {
			background-color: $colorInverted;

			@include media($smallLayout) {
				top: $headerHeightMedium + $navHeightMedium;
			}

			&::before {
				background-image: url("/assets/img/gradient-white.png");
			}

			.isp-body:not([data-isp-current-context='default']) & {
				// prevent to be sticky when context is open on glossary page
				position: static;
			}
		}

		.isp-glossary__blocks & {
			background-color: $colorMenuBg;

			@include media($smallLayout) {
				top: $headerHeightMedium;
			}

			@include media($mediumLayout) {
				top: $headerHeightMedium + $navHeightMedium;
			}

			&::before {
				background-image: url("/assets/img/gradient.png");
			}
		}
	}


	&__filterButton {
		@include isp-link($colorHighlight, $colorHighlightHover);
		@include font(detail);
		cursor: pointer;
		font-weight: bold;
		text-align: left;

	}


	&__glossaryNav {
		order: 2;

		@include media($smallLayout) {
			order: 1;
		}
	}


	&__itemTitle {
		@include font(base);
		font-weight: bold;
		margin-right: $base1;
		margin-left: - $base2;
		display: block;
		text-align: center;
		width: $base2;


		@include media($smallLayout) {
			margin-right: auto;
			margin-left: - $base4;
		}
	}


	&__link {
		display: inline;
		color: $colorInverted;
		text-decoration: none;

		.isp-page__blocks & {
			color: $colorBase;
		}
	}



	&__list {
		@include fadeIn($duration2);
		list-style-type: none;
		padding-top: $base3;
		padding-bottom: $base2;
		margin-top: 0;

		&.isp-js-hidden {
			@include fadeOut();
		}

		.isp-page__blocks & {
			padding-left: $base2;

			@include media($smallLayout) {
				padding-top: $base3;
				padding-left: 0;
			}
		}
	}


	&__listItem {
		display: flex;

		&.isp-js-hidden {
			display: none;
		}

		&:not(.isp-js-hidden) + &:not(.isp-js-hidden),
		& + &:not(.isp-js-hidden) {
			margin-top: $base1;

			@include media($smallLayout) {
				margin-top: $base4;
			}
		}
	}


	&__terms {
		column-count: 1;
		column-fill: balance;
		padding-right: $base1;
		width: 100%;

		.isp-page__blocks & {
			@include media($mediumLayout) {
				&.isp-js-multiColumned {
					column-count: 2;
				}
			}

			@include media($largeLayout) {
				&.isp-js-multiColumned {
					column-count: 3;
				}
			}
		}

		.isp-glossary__blocks & {
			@include media($largeLayout) {
				&.isp-js-multiColumned {
					column-count: 2;
				}
			}
		}
	}


	&__term {
		@include ease(opacity visibility, $duration2);
		break-inside: avoid;

		// NOTE: overflow prevent long term to break inside nex column
		overflow: hidden;

		&.isp-js-hidden {
			display: none;
		}

		&:not(:last-of-type) {
			margin-bottom: $base1;

			@include media($smallLayout) {
				margin-bottom: $base1 * 1.5;
			}
		}


		.isp-page__blocks & {
			@include media($smallLayout) {
				min-width: staticColumnSize(10, 10, $gutter);
			}

			@include media($largeLayout) {
				min-width: staticColumnSize(3, 9, $gutter);
			}
		}

		.isp-glossary__blocks & {
			@include media($smallLayout) {
				min-width: staticColumnSize(10, 10, $gutter);
			}

			@include media($largeLayout) {
				min-width: staticColumnSize(4, 8, $gutter);
			}
		}
	}


	&__title {
		@include font(h2);
		font-family: $fontFamilyAlternate;
		margin-left: $base2;
		margin-bottom: $base1;

		@include media($smallLayout) {
			margin-left: auto;
			width: staticColumnSize(6, 8, $gutter);
			margin-bottom: $base2;
		}

		@include media($mediumLayout) {
			width: staticColumnSize(8, 9, $gutter);
		}

		.isp-page__blocks & {
			display: none;
		}
	}


	&__text {
		display: inline;

		&--detail {
			margin-left: $base1 / 2;
		}

		.isp-page__blocks & {
			&--detail {
				color: $colorGlossaryTermDetail;
			}
		}

		.isp-glossary__blocks & {
			&--detail {
				color: $colorGlossaryTermDetailInverted;
			}
		}
	}



	&__wrapper {
		display: flex;
		flex-direction: row;
		position: relative;
		margin-left: auto;

		.isp-page__blocks & {

			@include media($smallLayout) {
				flex-direction: column;
				padding-right: $base4;
				width: staticColumnSize(10, 12, $gutter);
			}

			@include media($largeLayout) {
				width: staticColumnSize(9, 12, $gutter);
			}
		}

		.isp-glossary__blocks & {
			padding-left: $base2;
			padding-right: $base2;

			@include media($smallLayout) {
				flex-direction: column;
				width: staticColumnSize(6, 8, $gutter);
				padding-left: 0;
			}

			@include media($mediumLayout) {
				width: staticColumnSize(8, 9, $gutter);
				padding-right: 0;
			}
		}
	}
}

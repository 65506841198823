$colorBase: #0B1413;
$colorBgBase: #FFFFFF;

$colorAlternative: $colorBgBase;
$colorBgAlternative: #3B4444;

$colorInverted: $colorBgBase;
$colorBgInverted: #1F2E2E;

$colorBorderBase: $colorBase;
$colorBorderInverted: $colorInverted;

$colorBorderLight: #E2E9E9;

$colorBgPlot: #F1F4F4;
$colorRankingMapNoData: #BBC3C3;

$colorBgOverlay: rgba(0, 0, 0, 0.5);
$colorBgIndication: #8A9393;
$colorIndication: $colorBase;
$colorIndicationInverted: $colorInverted;

$colorDecoration: #8A9393;
$colorFooterDecorations: $colorDecoration;

$colorCoverCategory: #BBC3C3;
$colorConfiguratorAction: #BBC3C3;
$colorConfigurationActionDisabled: #8A9393;
$colorReportCoverTerm: $colorConfiguratorAction;

$colorSyncItemInfo: $colorCoverCategory;

$colorListChars: #3B4444;
$colorListCharsInverted: #BBC3C3;
$colorListChars: $colorBgAlternative;
$colorListCharsIverted: #BBC3C3;

$colorDivider: $colorDecoration;
$colorDividerInverted: $colorListCharsInverted;

$colorHighlight: #65BB4B;
$colorHighlightHover: #4E9438;
$colorHighlightDisabled: #B2DCA4;
$colorHighlightDisabledOnDark: #2A5C1B;
$colorButtonDisabledOnDark: #19420C;

$colorCustomIconHighlight: #8CCC78;

$colorQuoteBorder: $colorHighlight;

$colorBgSearchHighlight: #D8EED2;

//glossary nav
$colorMenuBg: #1F2E2E;
$colorGlossaryCover: #3B4444;


$colorGlossaryNavGradient: linear-gradient(
	transparentize(#1F2E2E, 0) 0%,
	transparentize(#1F2E2E, 1) 100%,
);

$colorGlossaryNavActive: #FFFFFF;
$colorGlossaryNavDisabled: #BBC3C3;
$colorGlossaryTermDetail: #8A9393;
$colorGlossaryTermDetailInverted: #BBC3C3;

$colorImgCaption: #596463;
$colorImgCaptionInverted: $colorListCharsInverted;

$colorPaginationItem: $colorImgCaption;

$colorVideoIconActive: $colorHighlight;
$colorBgVideoIcon: #1F2E2E;
$colorBgVideoIconActive: #0B1413;

$colorRankingGradient1: #08A098;
$colorRankingGradient2: #3D78BC;
$colorRankingGradient3: #EE4423;
$colorScrollBarBgTrack: #E2E9E9;
$colorScrollBarBgHandle: #8A9393;

$colorWarning: #EE4423;

$colorInputBase: #E2E9E9;
$colorInputBaseInverted: #8A9393;
$colorInputHover: #D8EED2;
$colorInputChecked: $colorHighlight;
$colorInputCheckedHover: $colorHighlightHover;
$colorConfiguratorCustomIcon: $colorDecoration;

$colorBgConfiguratorArea: transparentize($colorBgBase, 0.1);

$colorBgMapSelected: $colorHighlight;
$colorBgMapEnabled: #D8EED2;
$colorBgMapDisabled: #F0F4F4;
$colorBgMapHover: #B2DCA4;

$colorPageMenuIdentifier: #8A9393;
$colorPageMenuHover: #596463;
$colorPageMenuIcon: $colorHighlight;
$colorPageMenuIconActive: #4E9438;

$colorBgSearch: #F0F4F4;
$colorSearch: $colorBase;
$colorSearchPlaceholder: $colorDecoration;

$colorBgHomeInfographic: transparentize($colorBgBase, 0.8);

$configuratorHoverBg: linear-gradient(to right, $colorBgBase 0%, #F8FBFB 50%, $colorBgBase 90%);

$colorBgModal: transparentize($colorBase, 0.05);

$colorEditorialPageListDetail: #8A9393;
$colorEditorialPageListLead: #3B4444;

$colorLinkHover: #BBC3C3;

$colorFilterTogglerIcon: $colorDecoration;
$colorFilterBgToggler: $colorBorderLight;
$colorFilterSeparator: $colorBorderLight;
$colorFilterBorder: #BBC3C3;
$colorFilterBgItems: #F8FBFB;
$colorFiltersBgToggled: $colorFilterBorder;

$colorBgDownloadButton: #596463;

$colorRankingIndicator: $colorDecoration;// #003466;
$colorRankingPointer: #4E9438;
$colorRankingDetail: #8A9393;

@import "../../isp/blocks/page-container";

.isp-pageContainer {
	// a[href^=http]:not(.isp-ugc__link--action)::after {
	// 	font-style: italic;
	// 	content: attr(href);
	// }

	img {
		page-break-inside: avoid;
	}

}

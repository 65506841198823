@mixin isp-anchor($offset: 0) {
	display: block;
	position: relative;
	top: -$headerHeightSmall - $offset;
	visibility: hidden;

	@include media($smallLayout) {
		top: -$headerHeightMedium - $offset;// - $base3;
	}
}

.isp-rankingMap {
	position: relative;
	padding: $base2;


	&__header {
		display: block;
		margin-bottom: $base2;
		position: relative;
	}


	&__legend {
		@include font(legend);
		margin-top: $base2;
		margin-left: $base2;
		margin-right: $base2;
		position: relative;
	}


	&__legendBar {
		height: 100%;
		flex-grow: 1;
		position: relative;

		&--negative {
			margin-right: 1px;
		}

		&--positive {
			margin-left: 1px;

			&::before {
				background-color: $colorBgBase;
				content: '';
				position: absolute;
				width: 2px;
				right: 100%;
				top: 0;
				height: 100%;
			}
		}
	}

	&__legendBars {
		display: flex;
		height: $base * 1.5;
		flex-direction: row;
		justify-content: space-between;
	}


	&__legendNoDataBar {
		position: relative;
		margin-bottom: $base2;

		&::before {
			background-color: $colorRankingMapNoData;
			content: '';
			display: inline-block;
			height: $base * 1.5;
			vertical-align: middle;
			width: $base6;
		}
	}


	&__legendValue {
		position: absolute;
		top: 0;
		transform: translateX(-50%);

		&--negative {
			left: 0%;
		}

		&--positive {
			left: 100%;
		}

		&--zero {
			left: 50%;
		}
	}


	&__legendValues {
		height: getCalculatedLineHeight(detail);
		margin-top: $base;
		position: relative;
		width: 100%;
	}


	&__map {
		// display: none;
		position: relative;

		svg {
			width: 100%;
			height: auto;
		}

		// #container {
		// 	fill: $colorRankingMapNoData;
		// }
	}
}

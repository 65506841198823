.isp-search {
	color: $colorInverted;


	&__container {
		@include isp-modalContextContainer();
	}


	&__content {
		@include isp-modalContextBg();
		padding: $base3 0;
		position: relative;
		min-height: 140vh;

		@include media($smallLayout) {
			margin-left: staticColumnPush(4, 12, $gutter);
			padding: $base6 0;
		}

		@include media($largeLayout) {
			margin-left: staticColumnPush(7, 12, $gutter);
		}
	}


	// &__nav {
	// 	padding: 0 $base3;
	// 	min-height: 140vh;

	// 	@include media($smallLayout) {
	// 		padding: 0 $base6;
	// 		padding-left: staticColumnPush(2, 8, $gutter);
	// 	}

	// 	@include media($largeLayout) {
	// 		padding-left: staticColumnPush(1, 5, $gutter);
	// 	}
	// }
}

.isp-button {
	@include button($baseHalf);
	@include font(headerButton);

	&:not(:disabled) {
		cursor: pointer;
	}


	&--createReport {
		@include font(action);
		@include ease(color background-color, $duration2);
		cursor: default;
		background-color: $colorHighlightDisabledOnDark;
		border-radius: $base2;
		color: $colorButtonDisabledOnDark;
		line-height: $base4;
		padding: 0 $base2;
		pointer-events: none;

		@include media($smallLayout) {
			line-height: $base4;
		}

		@include media($largeLayout) {
			background-color: $colorHighlightDisabled;
			color: $colorBgBase;
		}


		&.isp-js-enabled {
			background-color: $colorHighlight;
			color: $colorBgBase;
			cursor: pointer;
			pointer-events: auto;

			@include onHover() {
				background-color: $colorHighlightHover;
			}
		}
	}

	&--download {
		background-color: $colorBgDownloadButton;
		border-radius: 50%;
		color: $colorInverted;
		height: $base * 3.2;
		line-height: $base * 3.2;
		width: $base * 3.2;
	}

	&--filtersToggler {
		@include font(filter);
		background-color: $colorFilterBgToggler;
		border-radius: $base * 1.5;
		height: $base3;
		justify-content: space-between;
		line-height: $base3;
		padding: 0 $base;
		text-align: left;

		&.isp-js-toggled {
			background-color: $colorFiltersBgToggled;
		}

		.isp-plot & {
			@include media($mediumLayout) {
				background-color: transparent;
				padding: 0;
			}
		}
	}

	&--mainNav {
		@include ease(color, $duration4);
		color: $colorBase;

		.isp-header.isp-js-covered:not(.isp-js-coveredByPageMenu) & {
			color: $colorInverted;
		}

		.isp-body[data-isp-current-context="glossary"] .isp-header__navItem--glossary &,
		.isp-body[data-isp-current-context="menu"] .isp-header__navItem--menu & {
			color: $colorHighlight;
		}
	}

	&--pagination {
		@include font(detail);
		font-weight: 600;
		color: $colorHighlight;

		@include onHover() {
			color: $colorHighlightHover;
		}
	}

	&--paginationArrow {
		@include isp-actionLink($color: $colorHighlight, $hoverColor: $colorHighlightHover);

		&::before {
			display: none;
		}
	}

	&--paginationCurrent,
	&--paginationSeparator {
		pointer-events: none;
		color: $colorPaginationItem;
	}

	&--plotDownload {
		background-color: $colorBgDownloadButton;
		border-radius: 50%;
		color: $colorInverted;
		height: $base * 3.2;
		line-height: $base * 3.2;
		width: $base * 3.2;
	}

	&--plotExpand {
		background-color: $colorBgDownloadButton;
		border-radius: 50%;
		color: $colorInverted;
		height: $base * 3.2;
		line-height: $base * 3.2;
		width: $base * 3.2;
	}

	&--searchReset {
		@include font(h1);
	}

	&--selectToggler {
		@include font(filter);
		background-color: $colorFilterBgToggler;
		border-top: 1px solid $colorFilterBorder;
		height: $base * 3.5;
		justify-content: space-between;
		line-height: $base * 3.5;
		padding: 0 $base;
		text-align: left;

		.isp-select:first-child & {
			border-top: 0;
		}

		.isp-select.isp-js-disabled & {
			color: $colorDecoration;
			background-color: lighten($colorFilterBgToggler, 2%);
		}

		@include media($mediumLayout) {
			border: 0;
			border-radius: $base * 1.5;
			height: $base3;
			line-height: $base3;
		}
	}

	&--submit {
		@include font(action);
		@include ease(color background-color, $duration2);
		background-color: $colorHighlight;
		border-radius: $base2;
		color: $colorBgBase;
		line-height: $base4;
		padding: 0 $base2;

		@include media($smallLayout) {
			line-height: $base4;
		}

		&:disabled,
		&.isp-js-disabled {
			background-color: $colorHighlightDisabled;
			cursor: default;
			pointer-events: none;
		}

		&:not(:disabled):not(.isp-js-disabled) {
			@include onHover() {
				background-color: $colorHighlightHover;
			}
		}
	}

	&--typeDescriptionToggler {
		color: $colorDecoration;
	}



	&__label {
		.isp-button--mainNav & {
			display: none;

			@include media($smallLayout) {
				display: inline-block;
			}
		}
	}


	&__icon {
		@include media($mediumLayout) {
			@include ease(transform, $duration2);
		}

		&--default {
			.isp-button.isp-js-toggled & {
				display: none;
			}
		}

		&--toggled {
			.isp-button:not(.isp-js-toggled) & {
				display: none;
			}
		}

		.isp-button--mainNav .isp-button__label + &,
		.isp-button--mainNav & + & {
			margin-left: 0;

			@include media($smallLayout) {
				margin-left: $baseHalf;
			}
		}

		.isp-button--filtersToggler & {
			color: $colorFilterTogglerIcon;
			height: $base * 1.2;
			position: relative;
			width: $base * 1.2;

			.isp-plot & {
				@include media($mediumLayout) {
					display: none;
				}
			}
		}

		.isp-button--selectToggler & {
			color: $colorFilterTogglerIcon;
			height: $base * 1.2;
			position: relative;
			width: $base * 1.2;
		}

		.isp-button--typeDescriptionToggler.isp-js-toggled & {
			@include ease(transform, $duration2);
			transform: rotate(180deg);
		}

		.isp-button--filtersToggler.isp-js-toggled &,
		.isp-button--selectToggler.isp-js-toggled & {
			transform: rotate(180deg);
		}

		.isp-button--plotExpand &--expand,
		.isp-button--plotExpand &--collapse {
			* + & {
				margin-left: 0;
			}
		}


		.isp-plot.isp-js-expanded .isp-button--plotExpand &--expand,
		.isp-plot:not(.isp-js-expanded) .isp-button--plotExpand &--collapse {
			display: none;
		}
	}
}

.isp-configuratorControl {
	@include font(configuratorControl);
	display: block;
	flex-grow: 0;
	flex-shrink: 0;
	height: auto;
	// allow enough room to store all the values in previous order for sorting them
	order: 1000;
	position: relative;


	& + & {
		margin-top: $base;
	}

	&--indicator {
		display: none;

		&.isp-js-enabled {
			display: block;
		}
	}

	&--areaGlobal {
		border-top: 1px solid $colorBorderLight;
		bottom: 0;
		left: 0;
		order: 1002;
		padding-top: $base;
		position: sticky;
		text-transform: uppercase;
		width: 100%;
		z-index: 2;

		.isp-configuratorControl + & {
			margin-top: $baseHalf;
		}

		&::before {
			@include media($largeLayout) {
				content: '';
				background-color: $colorBgBase;
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				right: -$base;
				z-index: 0;
			}
		}
	}

	&--topic {
		.isp-configurator:not(.isp-js-selected) & {
			@include media($largeLayout) {
				width: calc(50% - #{$gutter / 2});
				// float: left;
			}

			&:nth-child(2) {
				@include media($largeLayout) {
					margin-top: 0;
				}
			}

			&:nth-child(2n) {
				@include media($largeLayout) {
					margin-left: $gutter;
				}
			}
		}
	}


	&__activeAreasCount {
		text-transform: none;
	}


	&__description {
		@include ease(height, $duration2);
		overflow: hidden;
		position: relative;

		@include media($largeLayout) {
			display: none;
		}
	}


	&__descriptionContent {
		@include ease(opacity visibility, $duration2);
		left: 0;
		padding: $base 0;
		position: relative;
		top: 0;
		width: 100%;

		&.isp-js-collapsed {
			opacity: 0;
			position: absolute;
			visibility: hidden;
		}
	}


	&__descriptionToggler {
		position: absolute;
		right: 0;
		top: ($base * 4.8 / 2 - $base * 2.4 / 2 - $baseHalf);
		height: $base * 2.4;
		width: $base * 2.4;

		@include media($largeLayout) {
			display: none;
		}
	}


	&__icon {
		color: $colorInputBase;
		height: $base2;
		left: 0;
		line-height: 0;
		margin-top: -$base;
		position: absolute;
		top: getCalculatedLineHeight(configuratorControl) / 2;
		width: $base2;
		z-index: 0;

		@include media($smallLayout) {
			top: getCalculatedLineHeight(configuratorControl, $smallLayout) / 2;
		}

		&--default {
			.isp-configuratorControl__input:not(:disabled) ~ .isp-configuratorControl__label:hover & {
				color: $colorInputHover;
			}

			.isp-configuratorControl__input:checked ~ .isp-configuratorControl__label & {
				display: none;
			}
		}

		&--checked {
			color: $colorInputChecked;
			display: none;

			.isp-configuratorControl__input:not(:disabled) ~ .isp-configuratorControl__label:hover & {
				color: $colorInputCheckedHover;
			}

			.isp-configuratorControl__input:checked ~ .isp-configuratorControl__label & {
				display: block;
			}
		}


		&--custom {
			color: $colorConfiguratorCustomIcon;
			height: $base * 4.8;
			top: 0;
			margin-top: 0;
			width: $base * 4.8;

			.isp-configuratorControl__input:checked ~ .isp-configuratorControl__label & {
				color: $colorHighlight;
			}

			.isp-configuratorControl__input:not(:disabled):not(:checked) ~ .isp-configuratorControl__label:hover & {
				color: $colorCustomIconHighlight;
			}
		}


		[data-type="svg"] {
			width: 100%;
			height: 100%;
		}
	}


	&__input {
		&:disabled {
			opacity: $inputDisabledOpacity;
		}


		&--checkbox,
		&--radio {
			@include visuallyHidden();
		}
	}


	&__label {
		display: block;
		padding-left: $base3;
		position: relative;
		z-index: 1;

		.isp-configuratorControl--type & {
			min-height: $base * 4.8;
			padding-top: $base;
			padding-left: $base6;
			padding-right: $base3;

			@include media($largeLayout) {
				padding-right: 0;
			}
		}

		.isp-configuratorControl--topic & {
			min-height: $base * 4.8;
			padding-top: $base;
			padding-left: $base6;
		}


		.isp-configuratorControl__input:disabled ~ & {
			opacity: $inputDisabledOpacity;
		}

		.isp-configuratorControl__input:not(:disabled):not(:checked) ~ &:hover {
			cursor: pointer;
		}
	}


	&__labelValue {
		border-bottom: 1px solid transparent;
		display: inline-block;
		padding-bottom: $baseHalf;

		.isp-configuratorControl__input:checked ~ .isp-configuratorControl__label & {
			border-bottom-color: $colorInputChecked;
			font-weight: 600;
		}

		.isp-configuratorControl__input:not(:disabled):not(:checked) ~ .isp-configuratorControl__label:hover & {
			color: $colorHighlightHover;
			border-bottom-color: $colorCustomIconHighlight;
		}

		.isp-js-highlight {
			background-color: $colorBgSearchHighlight;
		}
	}


	&__subCountries {
		@include font(configuratorSubCountries);
		color: #8A9393;
		display: block;
	}
}

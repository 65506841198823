.isp-image {
	width: 100%;

	&__caption {
		@include font(detail);
		color: $colorImgCaption;
		margin-top: $base1 / 2;
	}

	&__img {
		width: 50%;
	}
}

.isp-featuredStory {
	&__frame {
		display: none;
	}


	&__info {
		position: relative;
	}


	&__link {
		display: none;
	}


	&__title {
		@include font(h3);
		position: relative;
		text-align: left;
	}
}

.isp-externalVideo {
	@include isp-subContainer($pushing: true);

	&__caption {
		@include font(detail);
		color: $colorImgCaption;
		margin-top: $base1 / 2;

		.isp-page--withBg & {
			color: $colorImgCaptionInverted;
		}
	}


	&__content {
		@include aspectRatio($videoRatio);
		position: relative;

		&::after {
			// background-color: $colorVideoOverlay;
			content: '';
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 2;
		}
	}


	&__icon {
		color: $colorInverted;
		height: $base4;
		left: 50%;
		margin-left: -$base2;
		margin-top: -$base2;
		position: absolute;
		text-align: center;
		top: 50%;
		width: $base2;

		&::before {
			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			background-color: $colorBgVideoIcon;
			height: $base8;
			width: $base8;
			border-radius: $base2 * 10;

			@include media($smallLayout) {
				height: $base1 * 15;
				width: $base1 * 15;
			}

			@include media($largeLayout) {
				height: $base1 * 20;
				width: $base1 * 20;
			}
		}

		@include onHover('.isp-externalVideo__link', '&') {
			color: $colorVideoIconActive;

			&::before {
				background-color: $colorBgVideoIconActive;
			}
		}


		[data-type="svg"] {
			width: 100%;
			height: 100%;
			fill: currentColor;
			stroke: currentColor;
		}
	}


	&__img {
		@include objectFit(cover);
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;
	}


	&__link {
		@include ease(color, $duration2);
		color: $colorBase;
		display: block;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 3;
	}


	&__player {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 4;
	}
}

$base: 1rem;
$base0: $base * 0;
$base1: $base * 1;
$base2: $base * 2;
$base3: $base * 3;
$base4: $base * 4;
$base5: $base * 5;
$base6: $base * 6;
$base7: $base * 7;
$base8: $base * 8;
$base9: $base * 9;
$base10: $base * 10;
$baseHalf: $base / 2;

$videoRatio: 16 / 9;

$blockSpacing: 1cm;

$containerPaddingSmall: $base2;
$containerPaddingMedium: $base5;
$containerPaddingLarge: $base10;

$containerMaxWidth: 168 * $base;

$gutter: $base * 3.2;

$headerHeightSmall: $base6;
$headerHeightMedium: $base8;

$indicationHeight: 2px;

$configuratorLineIndicatorHeight: 3px;
$configuratorLineIndicatorOffset: -1px;

$rankingVerticalSize: $base1 * 14;
$rankingIndicatorHorizontalSize: 5px;
$rankingIndicatorVerticalSize: $base2;

$glossarySideSpacing: $base2;

$pageMenuIconSpacing: $base5;
$pageMenuOverlaySpacing: 75vh;
$pageMenuMarkedHeight: 3px;



$configuratorSummaryBaseHeight: $base * 10;
$customScrollbarWidth: 6px;

$reportCoverMapMaxHeightSmall: 40 * $base;
$reportCoverMapMaxHeightMedium: 50 * $base;
$reportCoverMapMaxHeightLarge: 51 * $base;


$homeInfographicBigBorderSize: 3px;

$modalIconSpacing: $base3;




$sizeVariants: (
	reportCoverMap: (
		max-height: $reportCoverMapMaxHeightSmall,
		padding: 0 $containerPaddingSmall,
	),
	blockVerticalSpacing: (
		padding-top: $base3,
	),
	blockVerticalSpacingWithTitle: (
		padding-top: $base3,
	),
	blockVerticalSpacingHome: (
		padding-top: $base6,
	),
	coverVerticalSpacing: (
		padding-top: $headerHeightSmall + $base3,
	),
	coverVerticalSpacingHome: (
		padding-top: $headerHeightSmall + $base4,
	),
	blockTitleTopSpacing: (
		value: $base3,
	),
	menuHorizontalSpacing: (
		padding-left: $base4,
		padding-right: $containerPaddingSmall + $base2,
	),
	glossaryNavSpacing: (
		padding-top: $headerHeightMedium + $base6,
		padding-right: $containerPaddingMedium
	),
	pageMenuVerticalSpacings: (
		top: $headerHeightSmall + $base2,
	)
);

.isp-articlesSlider {
	@include font(base);


	&__articleTitle {
		padding-bottom: $base2;
	}


	&__caption {
		@include font(detail);
		bottom: 0;
		padding: 0 $base1;
		position: absolute;
	}


	&__content {
		& > *:not(:first-child):not(.isp-articlesSlider__articleTitle) {
			padding-top: $base2;
		}
	}

	&__contentLink {
		color: $colorBase;
		text-decoration: none;
	}


	&__details {
		@include font(detail);
		margin-bottom: 0;
	}


	&__image {
		pointer-events: none;
		position: relative;
	}


	&__img {
		width: 50%;
	}


	&__indication {
		display: none;
	}


	&__items {
		text-decoration: none;
	}


	&__item {
	}


	&__link {
		display: none;
	}

	&__nav {
		position: relative;
	}

	&__title {
		margin-bottom: $base1;
	}

	&__text {
		@include font(detail);
	}
}

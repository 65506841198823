.isp-body {
	@include font(base);
	color: $colorBase;
	font-family: $fontFamilyMain;


	&__loading {
		display: none;
	}
}

.isp-homeInfographic {
	&__item {
		& + & {
			margin-top: $base2;
		}
	}


	&__itemText {
		@include font(base);
		width: 100%;

		.isp-homeInfographic__itemTitle + & {
			// margin-top: $base2;
		}
	}


	&__itemTitle {
		@include font(h3);
		width: 100%;
	}
}

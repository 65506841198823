.isp-callToAction {
	@include minAspectRatio(1);
	@include font(action);
	@include ease(color background-color, $duration2);
	align-items: flex-start;
	background-color: $colorBgBase;
	border-radius: 50%;
	color: $colorBase;
	display: inline-flex;
	position: relative;
	text-align: center;
	vertical-align: top;

	&:hover {
		background-color: $colorBase;
		color: $colorBgBase;
	}

	&--alternate {
		color: $colorInverted;
		border-radius: 2rem;
		background-color: $colorHighlight;

		@include media($mediumLayout) {
			border-radius: 50%;
		}

		&::before {
			padding-bottom: 0;

			@include media($mediumLayout) {
				padding-bottom: 100%;
			}
		}
	}


	&__icon {
		color: inherit;
		display: block;
		left: 50%;
		line-height: 0;
		margin-top: $base2;
		position: absolute;
		top: 50%;
		transform: translateX(-50%);
		z-index: 1;


		[data-type="svg"] {
			width: 100%;
			height: 100%;
		}

		.isp-callToAction--alternate & {
			margin-top: 0;
			position: static;
			transform: translateX(0);

			@include media($mediumLayout) {
				margin-top: $base2;
				position: absolute;
				transform: translateX(-50%);
			}
		}
	}


	&__label {
		border-bottom: 1px solid currentColor;
		display: block;
		margin-left: $base;
		margin-right: $base;
		margin-top: 50%;
		padding-bottom: $baseHalf;
		position: relative;
		transform: translateY(-100%);
		z-index: 1;

		@include media($smallLayout) {
			margin-left: $base4;
			margin-right: $base4;
		}

		.isp-callToAction--alternate & {
			border-bottom: 0;
			margin-top: 0;
			padding-bottom: 0;
			transform: translateY(0);

			@include media($mediumLayout) {
				border-bottom: 1px solid currentColor;
				margin-top: 50%;
				padding-bottom: $baseHalf;
				transform: translateY(-100%);
			}
		}
	}


	&__link {
		color: inherit;
		display: block;
		text-decoration: none;

		&::before {
			content: '';
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 0;
		}

		.isp-callToAction--alternate & {
			align-items: center;
			display: flex;
			padding: $base1 $base3 $base1 $base2;

			@include media($mediumLayout) {
				display: inline;
				padding: 0;
			}
		}
	}
}
